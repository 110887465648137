import React from "react"
import { Link } from "gatsby"
import Logo from "../assets/nessy.svg"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  if (isRootPath) {
    header = (
      <h1 className="main-heading">
        <Link to="/">
          <img className="index-logo" src={Logo} alt="Volver al inicio" />
          {title}
        </Link>
      </h1>
    )
  } else {
    header = (
      <div className="h1">
        <Link to="/">
          <img className="index-logo" src={Logo} alt="Volver al inicio" />
          {title}
        </Link>
      </div>
    )
  }

  return (
    <>
      <header className="header">
        <div className="header-container">
          {header}
          <input id="menu-toggle" type="checkbox" />
          <label htmlFor="menu-toggle">
            <svg
              id="open-menu"
              xmlns="http://www.w3.org/2000/svg"
              style={{ height: 28, width: 28 }}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
            <svg
              id="close-menu"
              xmlns="http://www.w3.org/2000/svg"
              style={{ height: 28, width: 28 }}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </label>
          <nav className="navigation" role="navigation">
            <Link to="/">Inicio</Link>
            <Link to="/about">Sobre nosotros</Link>
          </nav>
        </div>
      </header>
      <div className="global-wrapper" data-is-root-path={isRootPath}>
        <main>{children}</main>
        <footer className="footer-text">
          <hr />
          Hecho por Codeness con{" "}
          <span role="img" aria-label="cariño">
            ❤️
          </span>
          <br />© {new Date().getFullYear()}{" "}
          <a target="_blank" rel="noreferrer" href="https://codeness.io">
            Codeness
          </a>
        </footer>
      </div>
    </>
  )
}

export default Layout
